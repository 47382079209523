<template>

  <v-card :loading="loaders.loading" flat>

    <v-card-title>

    </v-card-title>

    <v-card-text>

      <image-upload-controller v-if="loaders.ready"
                               :image="illustration.src"
                               :url="'upload.illustration'"
                               :keyform="'illustration_form[image]'"
                               @remove="()=> image_src = ''"
                               @uploaded="coverUploaded"
      ></image-upload-controller>

    </v-card-text>
    <v-card-text>

      <h3>Detail de l'illustration</h3>
      <v-row>
        <v-col>
<!--          <v-select
            :loading="loaders.categories"
            v-model="illustration.category_id"
            outlined
            dense
            :items="categories"
            label="Categorie"
            item-value="id"
            item-text="name"
          >
            <template v-slot:selection="data">
              {{ data.item.name['fr'] }}
            </template>
            <template v-slot:item="data">
              <v-list-item-content>
                {{ data.item.name['fr'] }}
              </v-list-item-content>
            </template>
          </v-select>-->
          <v-text-field outlined v-model="illustration.title" label="Titre"></v-text-field>

          <v-textarea outlined v-model="illustration.description" label="Description"></v-textarea>

        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="mt-6 pb-16" >
      <v-switch
        v-model="illustration.status"
        hide-details
        value="1"
        true-value="1"
        false-value=""
        :label="` ${illustration.status == 1 ? 'Public' : 'Brouillon'}`"
      ></v-switch>
      <v-spacer></v-spacer>
      <v-alert dense v-if="!illustration.image"  color="warning">
        Vous devez charger une image avant de pouver publier
      </v-alert>
      <v-btn class="ml-3" :disabled="!illustration.image" color="primary" :loading="loaders.loading" @click="save(1)">
        Publier
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import {computed, ref} from '@vue/composition-api'
import {Drequest} from '@/plugins/Drequest'
import {getVuetify} from '@/plugins/utils'
import Banner from '@/components/Banner'
import {useRouter} from "@/utils";
import FollowToggleComponent from "@/components/FollowToggleComponent.vue";
import ProfileItem from "@/components/ProfileItem.vue";
import ImageUploadController from "@/components/ImageUploadController.vue";
import ChapterFormComponent from "@/components/ChapterFormComponent.vue";
// import { useMeta } from 'vue-meta'

export default {
  components: {
    ChapterFormComponent,
    ImageUploadController,
    ProfileItem, FollowToggleComponent, Banner
  },
  setup() {
    //useMeta({ title: 'Some Page' })
    const dialog = ref({
      newillustration: false,
      imageviewer: false,
      authentication: false,
    })

    const loaders = ref({
      config: false,
      loading: true,
      ready: false,
    })

    const {router} = useRouter()
    const route = router.currentRoute

    const user_id = (localStorage.getItem('user_id'))
    const user = ref(Drequest.getUser())

    const illustration = ref({id: parseInt(route.params.id), image:'', uploaddir:''})
    const e1 = ref(1)
    const cover = ref(1)
    const image_src = ref("")
    const categories = ref([])

    if(illustration.value.id)
      Drequest.api('detail.illustration?id='+illustration.value.id)
        .get(response => {
          console.log(response)
          illustration.value = response.illustration
          loaders.value.loading = false
        })
    Drequest.api('lazyloading.tree-item?dfilters=on&tree.name:eq=category')
      .get(response => {
        console.log(response)
        categories.value = response.listEntity
        loaders.value.loading = false
      })

      loaders.value.ready = true

    const coverUploaded = (response, file) => {
      console.log('asdfasdfasdf  a sdfasdfsa', response)
      //illustration.value = file
      // image_src.value = response..cover_image
      illustration.value.image = response.illustration.image
      illustration.value.uploaddir = response.illustration.uploaddir
    }

    const save = () => {

        loaders.value.loading = true

      if(!illustration.value.title){
        if (!confirm('Vous n avez pas renseigne le titre de votre illustration. Le nom de l image sera pris par defaut. Vous confirmez votre action?'))
          return ;

        illustration.value.title = illustration.value.image
      }

        let fd = new FormData();
        fd.append('illustration_form[title]', illustration.value.title)
        fd.append('illustration_form[status]', illustration.value.status)
        fd.append('illustration_form[description]', illustration.value.description)
        fd.append('illustration_form[image]', illustration.value.image)
        fd.append('illustration_form[uploaddir]', illustration.value.uploaddir)
        // fd.append('illustration_form[image]', image_src )
        fd.append('illustration_form[user.id]', user_id )

        var url = 'create.illustration';
        if (illustration.value.id)
          url = 'update.illustration?id='+illustration.value.id;

        Drequest.api(url)
          .data(fd)
          .post((response)=> {
            console.log(response)
            loaders.value.loading = false

            router.push("/gallery")

          })
          .fail(e => {
            loaders.value.loading = false
            alert('Oops Il y a eu un probleme de connexion essayer de nouveau SVP !')
            console.log(e)
          })

        return 0


    }

    return {
      coverUploaded,
      save,

      categories,
      user,
      loaders,
      illustration,
      image_src,
      e1,
      cover,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
